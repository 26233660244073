<template>
  <v-dialog max-width="600px" eager v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn
        elevation="0"
        color="primary"
        small
        class="ml-3"
        outlined
        v-on="on"
        @click="showDialog"
        >Add New Record</v-btn
      >
    </template>

    <v-card>
      <v-card-title>
        <h2 class="title">{{ title }}</h2>
        <v-btn
          small
          text
          fab
          color="grey darken-1"
          @click="closeDialog()"
          class="close-btn"
        >
          <v-icon medium>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form class="px-3" ref="form" enctype="multipart/form-data">
          <v-container class="px-0">
            <v-row>
              <v-col cols="12" class="py-0">
                <v-select
                  :items="scheduleForList"
                  v-model="scheduleFor"
                  item-text="title"
                  item-value="id"
                  label="Schedule For*"
                  @change="loadReletiveData"
                  :rules="rules.select"
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="12" class="py-0" v-if="scheduleFor != null">
                <v-select
                  :items="vehicleList"
                  v-model="selectedVehicle"
                  item-text="refId"
                  item-value="id"
                  :label="`Select ${scheduleFor.title}*`"
                  :rules="rules.select"
                   @change="onChangeVehicle"
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="8" class="py-0">
                <v-text-field
                  label="VIN*"
                  disabled
                  v-model="vin"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-text-field
                  label="Fuel Type*"
                  disabled
                  v-model="fuelType"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  disabled
                  label="License Issue State/Province"
                  v-model="issueProvince"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  disabled
                  label="License Plate Number"
                  v-model="plateNumber"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="py-0">
                <v-text-field
                  label="Year"
                  disabled
                  v-model="year"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="py-0">
                <v-text-field
                  label="Make"
                  disabled
                  v-model="make"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4" class="py-0">
                <v-text-field
                  label="Model"
                  disabled
                  v-model="model"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" class="py-0">
                <v-text-field
                  label="Under Taking Company"
                  disabled
                  v-model="company"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0" v-if="scheduleFor != null">
                <v-select
                  :items="maintenanceList"
                  v-model="maintenanceType"
                  item-text="title"
                  item-value="id"
                  :label="getTypeLabel(scheduleFor.title)"
                  @change="onChangeMaintenanceType"
                  :rules="rules.select"
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="12" md="12" class="py-0">
                <v-textarea
                  label="Maintenance Details*"
                  v-model="maintenanceDetail"
                  required
                  ref="mDetail"
                  :rules="rules.input"
                ></v-textarea>
              </v-col>

              <v-col cols="12" md="5" class="py-0" v-if="scheduleFor != null">
                <v-text-field
                  label="Cost"
                  v-model="cost"
                  prefix="$"
                  ref="mCost"
                  :rules="rules.cost"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="7" class="py-0" v-if="scheduleFor != null">
                <v-menu
                  ref="maintenanceDateMenu"
                  v-model="maintenanceDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="maintenanceDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="maintenanceDate"
                      :label="getDateLabel(scheduleFor.title)"
                      readonly
                      ref="mDate"
                      v-on="on"
                      :rules="rules.date"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="maintenanceDate"
                    no-title
                    scrollable
                    @input="$refs.maintenanceDateMenu.save(maintenanceDate)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="maintenanceDateMenu = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.maintenanceDateMenu.save(maintenanceDate)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-btn
                  text
                  class="primary mx-0 mt-3"
                  @click="submit()"
                  :loading="loading"
                  :disabled="loading"
                  >{{ btnLabel }}</v-btn
                >
                <v-btn
                  class="ml-3 mx-0 mt-3"
                  color="error"
                  outlined
                  v-show="isVisibleDeleteBtn"
                  text
                  :loading="loading"
                  :disabled="loading"
                  @click="deleteItem()"
                  >Delete</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
  <script>
import EventBus from "@/eventBus";
import axios from "axios";
import CustomLog from "@/plugins/customlog";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  props: ["projectId"],
  data() {
    return {
      isVisibleDeleteBtn: false,
      maintenanceDate: "",
      maintenanceDateMenu: false,
      maintenanceDetail: "",
      cost: "",
      userInfo: null,
      title: "New Maintenance Record",
      btnLabel: "Add Record",
      id: 0,
      rules: {
        select: [(v) => !!v || "Item is required"],
        select2: [(v) => v.length > 0 || "Item is required in select 2"],
        input: [
          (v) => (v && v.length >= 3) || "Minimum length is 3 characters",
        ],
        date: [(v) => !!v || "Item is required"],
        cost: [(v) => /^[0-9.]+$/.test(v) || "Enter valid integer value"],
      },
      loading: false,
      dialog: false,
      action: "add",
      maintenanceList: [],
      maintenanceType: {},
      scheduleForList: [
        { title: "Truck", id: 1 },
        { title: "Trailer", id: 2 },
      ],
      scheduleFor: { title: "Truck", id: 1 },
      vehicleList: [],
      selectedVehicle: {
        id: "",
        refId: "",
        vin: "",
        plateNumber: "",
        issueProvince: "",
        make: "",
        model: "",
        year: "",
        fuelType: {
          id: "",
          title: "",
        },
        company: {
          id: "",
          title: "",
        },
      },
      vin: "",
      fuelType: "",
      issueProvince: "",
      plateNumber: "",
      year: "",
      make: "",
      model: "",
      company: "",
    };
  },

  methods: {
    onChangeVehicle(){
      console.log('====================================');
      console.log(this.selectedVehicle.vin);
      console.log('====================================');
      this.setData();
    },
    deleteItem() {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 129);
      formData.append("adminId", this.userInfo.id);
      formData.append("id", this.id);
      formData.append("action", "delete");
      axios
        .post(BASE_URL + "maintenanceReport.php", formData)
        .then(function (response) {
          vm.loading = false;
          CustomLog.log(response);
          vm.message = response.data.message;
          if (!response.data.error) {
            EventBus.$emit("maintenance-update", vm.error);
            vm.dialog = false;
            vm.$refs.form.reset();
          }
        })
        .catch(function (error) {
          vm.loading = false;
          CustomLog.log(error);
        });
    },
    getTypeLabel(type) {
      if (type == "Driver") {
        return "Expire Type*";
      } else {
        return "Maintenance Type*";
      }
    },
    getDateLabel(type) {
      if (type == "Driver") {
        return "Expire Date*";
      } else {
        return "Maintenance Date*";
      }
    },
    getDaysLabel(type) {
      if (type == "Driver") {
        return "Expire Frequecy (Days)*";
      } else {
        return "Maintenance Frequecy (Days)*";
      }
    },
    submit() {
      if (this.$refs.form.validate()) {
        const vm = this;
        vm.loading = true;
        var formData = new FormData();
        formData.append("adminId", vm.userInfo.id);
        formData.append("itemId", vm.selectedVehicle.id);
        formData.append("for", vm.scheduleFor.id);
        formData.append("type", vm.maintenanceType.id);
        formData.append("detail", vm.maintenanceDetail);
        formData.append("date", vm.maintenanceDate);
        formData.append("cost", vm.cost);
        formData.append("action", vm.action);
        if (vm.action == "update") {
          formData.append("id", vm.id);
        }
        axios
          .post(BASE_URL + "maintenanceReport.php", formData)
          .then(function (response) {
            vm.loading = false;
            CustomLog.log(response);
            vm.message = response.data.message;
            let messageObject = {
              message: vm.message,
              isError: response.data.error,
            };
            EventBus.$emit("display-message", messageObject);
            if (!response.data.error) {
              if (vm.action == "update") {
                EventBus.$emit("maintenance-update", vm.error);
              } else {
                EventBus.$emit("maintenance-added", vm.error);
              }
            }
            vm.dialog = false;
            // vm.name = "";
            vm.$refs.form.reset();
          })
          .catch(function (error) {
            vm.loading = false;
            CustomLog.log(error);
            let messageObject = {
              message: "Some error occured!",
              isError: true,
            };
            EventBus.$emit("display-message", messageObject);
          });
      }
    },
    uploadFiles() {},
    closeDialog() {
      this.dialog = false;
    },
    showDialog() {
      this.btnLabel = "Add Record";
      this.name = "";
      //this.dialog = true;
      this.action = "add";
      this.title = "New Maintenance Record";
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    pickFile() {
      this.$refs.insFiles.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      CustomLog.log(files[0].name);
      if (files[0] !== undefined) {
        this.attachmentName = files[0].name;
        if (this.attachmentName.lastIndexOf(".") <= 0) {
          return;
        }
      }
    },
    loadDefaultData(action) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 129);
      formData.append("adminId", this.userInfo.id);
      formData.append("action", action);
      formData.append("scheduleFor", this.scheduleFor.id);
      axios
        .post(BASE_URL + "setting.php", formData)
        .then(function (response) {
          vm.loading = false;
          CustomLog.log(response);
          vm.message = response.data.message;
          if (!response.data.error) {
            vm.maintenanceList = response.data.list;
            if (vm.maintenanceList.length > 0 && vm.maintenanceType == null) {
              vm.maintenanceType = response.data.list[0];
            }
          }
        })
        .catch(function (error) {
          vm.loading = false;
          CustomLog.log(error);
        });
    },
    loadReletiveData(e) {
      this.loadVehicleList(e);
      this.loadDefaultData("maintenanceType");
    },
    onChangeMaintenanceType(e) {
      this.maintenanceType = e;
    },
    loadVehicleList(e) {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 129);
      formData.append("adminId", this.userInfo.id);
      formData.append("action", "get");
      // switch url
      var url = "truck.php";
      if (e.id == 2) {
        url = "trailer.php";
      } else if (e.id == 3) {
        url = "driver.php";
      }
      axios
        .post(BASE_URL + url, formData)
        .then(function (response) {
          vm.loading = false;
          CustomLog.log(response);
          vm.message = response.data.message;
          if (!response.data.error) {
            vm.vehicleList = response.data.list;
            if (vm.vehicleList.length > 0) {
              if (vm.action == "add") {
                vm.selectedVehicle = response.data.list[0];
                vm.setData();
              }
            }
          }
        })
        .catch(function (error) {
          vm.loading = false;
          CustomLog.log(error);
        });
    },
    showDeleteBtn(e) {
      if (e.altKey && e.shiftKey && e.code === "KeyD") {
        if (this.action == "update") {
          this.isVisibleDeleteBtn = true;
        }
      }
    },
    setData() {
      let vm = this;
      vm.vin = vm.selectedVehicle.vin;
      vm.fuelType = vm.selectedVehicle.fuelType.title;
      vm.issueProvince = vm.selectedVehicle.issueProvince;
      vm.plateNumber = vm.selectedVehicle.plateNumber;
      vm.year = vm.selectedVehicle.year;
      vm.make = vm.selectedVehicle.make;
      vm.model = vm.selectedVehicle.model;
      vm.company = vm.selectedVehicle.company.title;
    },
  },
  computed: {},
  mounted() {
    this.action = "add";
    EventBus.$on("edit-maintenance", (data) => {
      this.scheduleFor = data.for;
      this.maintenanceDate = data.dateForPicker;
      this.maintenanceDetail = data.detail;
      this.cost = data.cost;
      this.maintenanceType = data.type;
      this.selectedVehicle = data.itemInfo;
      this.id = data.id;
      this.btnLabel = "Update Record";
      this.dialog = true;
      this.action = "update";
      this.title = "Update Maintenance Record";
      this.setData();
      this.loadVehicleList(this.scheduleFor);
      this.loadDefaultData("maintenanceType");
      this.isVisibleDeleteBtn = false;
    });
    EventBus.$on("add-maintenance-type", (data) => {
      this.loadDefaultData(data);
    });
  },
  created() {
    this.userInfo = this.$store.state.loggedinInfo;
    if (this.userInfo.id != null) {
      this.loadDefaultData("maintenanceType");
      this.loadVehicleList(this.scheduleFor);
      window.addEventListener("keydown", this.showDeleteBtn);
    }
  },
};
</script>
  
  <style scoped>
.close-btn {
  position: absolute;
  right: 10px;
}
</style>
  