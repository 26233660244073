var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AddLoader',{attrs:{"dialog":_vm.loading}}),_c('v-container',{attrs:{"fluid":""}},[(_vm.items.length == 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12 py-3"}},[_c('div',{staticClass:"body"},[_vm._v(_vm._s(_vm.resultMsg))])])],1):_vm._e(),_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[_vm._v(" Manage Trucks "),_c('AddTruck'),_c('v-spacer'),_c('v-text-field',{staticClass:"search-field",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primary","small":"","outlined":""},on:{"click":function($event){return _vm.exportData()}}},[_vm._v("Export "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-microsoft-excel")])],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":"","color":"primary","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cog")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-list',_vm._l((_vm.fieldsList),function(truckField,index){return _c('v-list-item',{key:index,staticStyle:{"min-height":"38px"}},[_c('v-list-item-action',{staticClass:"my-1"},[_c('v-checkbox',{staticClass:"check-box",attrs:{"small":"","color":"blue","label":("  " + (truckField.title))},model:{value:(truckField.status),callback:function ($$v) {_vm.$set(truckField, "status", $$v)},expression:"truckField.status"}})],1)],1)}),1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.saveFieldsSetting()}}},[_vm._v(" Save ")])],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"disable-pagination":"","fixed-header":"","hide-default-footer":"","height":"80vh"},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","small":"","tile":"","elevation":"0"},on:{"click":function($event){return _vm.update(item)}}},[_vm._v("Update")])]}},{key:"item.fuelType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fuelType.title)+" ")]}},{key:"item.mcCertificate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.mcCertificate.title)+" ")]}},{key:"item.nmftaScac",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nmftaScac.title)+" ")]}},{key:"item.annualSafetyDateExtended",fn:function(ref){
var item = ref.item;
return [_c('div',{class:("" + (item.highlightAnnualSafetyDate
                ? 'red--text font-weight-bold'
                : ''))},[_vm._v(" "+_vm._s(item.annualSafetyDateExtended)+" ")])]}},{key:"item.pmDateExtended",fn:function(ref){
            var item = ref.item;
return [_c('div',{class:("" + (item.highlightPmDate ? 'red--text font-weight-bold' : ''))},[_vm._v(" "+_vm._s(item.pmDateExtended)+" ")])]}},{key:"item.insuranceSlipDate",fn:function(ref){
            var item = ref.item;
return [_c('div',{class:("" + (item.highlightInsuranceSlipDate
                ? 'red--text font-weight-bold'
                : ''))},[_vm._v(" "+_vm._s(item.insuranceSlipDate)+" ")])]}},{key:"item.transponderDate",fn:function(ref){
            var item = ref.item;
return [_c('div',{class:("" + (item.highlightTransponderDate
                ? 'red--text font-weight-bold'
                : ''))},[_vm._v(" "+_vm._s(item.transponderDate)+" ")])]}},{key:"item.cvorDate",fn:function(ref){
            var item = ref.item;
return [_c('div',{class:("" + (item.highlightCvorDate ? 'red--text font-weight-bold' : ''))},[_vm._v(" "+_vm._s(item.cvorDate)+" ")])]}},{key:"item.iftaDate",fn:function(ref){
            var item = ref.item;
return [_c('div',{class:("" + (item.highlightIftaDate ? 'red--text font-weight-bold' : ''))},[_vm._v(" "+_vm._s(item.iftaDate)+" ")])]}},{key:"item.kentuckyDate",fn:function(ref){
            var item = ref.item;
return [_c('div',{class:("" + (item.highlightKentuckyDate ? 'red--text font-weight-bold' : ''))},[_vm._v(" "+_vm._s(item.kentuckyDate)+" ")])]}},{key:"item.leaseExpireDate",fn:function(ref){
            var item = ref.item;
return [_c('div',{class:("" + (item.highlightLeaseExpireDate
                ? 'red--text font-weight-bold'
                : ''))},[_vm._v(" "+_vm._s(item.leaseExpireDate)+" ")])]}},{key:"item.mcs90Date",fn:function(ref){
            var item = ref.item;
return [_c('div',{class:("" + (item.highlightMcs90Date ? 'red--text font-weight-bold' : ''))},[_vm._v(" "+_vm._s(item.mcs90Date)+" ")])]}},{key:"item.pinkSlipDate",fn:function(ref){
            var item = ref.item;
return [_c('div',{class:("" + (item.highlightPinkSlipDate ? 'red--text font-weight-bold' : ''))},[_vm._v(" "+_vm._s(item.pinkSlipDate)+" ")])]}},{key:"item.newMaxicoPermitDate",fn:function(ref){
            var item = ref.item;
return [_c('div',{class:("" + (item.highlightNewMaxicoPermitDate
                ? 'red--text font-weight-bold'
                : ''))},[_vm._v(" "+_vm._s(item.newMaxicoPermitDate)+" ")])]}},{key:"item.newYorkPermitDate",fn:function(ref){
            var item = ref.item;
return [_c('div',{class:("" + (item.highlightNewYorkPermitDate
                ? 'red--text font-weight-bold'
                : ''))},[_vm._v(" "+_vm._s(item.newYorkPermitDate)+" ")])]}},{key:"item.originPermitDate",fn:function(ref){
            var item = ref.item;
return [_c('div',{class:("" + (item.highlightOriginPermitDate
                ? 'red--text font-weight-bold'
                : ''))},[_vm._v(" "+_vm._s(item.originPermitDate)+" ")])]}},{key:"item.ucrDate",fn:function(ref){
            var item = ref.item;
return [_c('div',{class:("" + (item.highlightUcrDate ? 'red--text font-weight-bold' : ''))},[_vm._v(" "+_vm._s(item.ucrDate)+" ")])]}},{key:"item.nmftaDate",fn:function(ref){
            var item = ref.item;
return [_c('div',{class:("" + (item.highlightNmftaDate ? 'red--text font-weight-bold' : ''))},[_vm._v(" "+_vm._s(item.nmftaDate)+" ")])]}},{key:"item.cabCardDate",fn:function(ref){
            var item = ref.item;
return [_c('div',{class:("" + (item.highlightCabCardDate ? 'red--text font-weight-bold' : ''))},[_vm._v(" "+_vm._s(item.cabCardDate)+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }