<template>
  <div>
    <AddLoader :dialog="loading" />
    <v-container fluid>
      <v-row v-if="items.length == 0">
        <v-col cols="12 py-3">
          <div class="body">{{ resultMsg }}</div>
        </v-col>
      </v-row>
      <v-card tile>
        <v-card-title>
          Manage Trucks <AddTruck />
          <v-spacer></v-spacer>

          <v-text-field
            v-model="search"
            class="search-field"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-btn
            color="primary"
            small
            class="ml-3"
            outlined
            @click="exportData()"
            >Export <v-icon small>mdi-microsoft-excel</v-icon></v-btn
          >
          <!-- start setting icons menu-->
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                class="mx-2"
                color="primary"
                outlined
                v-bind="attrs"
                v-on="on"
                ><v-icon>mdi-cog</v-icon></v-btn
              >
            </template>

            <v-card>
              <v-list>
                <v-list-item
                  style="min-height: 38px"
                  v-for="(truckField, index) in fieldsList"
                  :key="index"
                >
                  <v-list-item-action class="my-1">
                    <v-checkbox
                      small
                      class="check-box"
                      v-model="truckField.status"
                      color="blue"
                      :label="`  ${truckField.title}`"
                    ></v-checkbox>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn text @click="menu = false"> Cancel </v-btn>
                <v-btn color="primary" text @click="saveFieldsSetting()">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <!-- end setting icon menu-->
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          disable-pagination
          fixed-header
          hide-default-footer
          
          height="80vh"
        >
          <template v-slot:item.action="{ item }">
            <v-btn
              color="primary"
              small
              tile
              elevation="0"
              @click="update(item)"
              >Update</v-btn
            >
          </template>

          <template v-slot:item.fuelType="{ item }">
            {{ item.fuelType.title }}
          </template>
          <template v-slot:item.mcCertificate="{ item }">
            {{ item.mcCertificate.title }}
          </template>
          <template v-slot:item.nmftaScac="{ item }">
            {{ item.nmftaScac.title }}
          </template>

          <template v-slot:item.annualSafetyDateExtended="{ item }">
            <div
              :class="`${
                item.highlightAnnualSafetyDate
                  ? 'red--text font-weight-bold'
                  : ''
              }`"
            >
              {{ item.annualSafetyDateExtended }}
            </div>
          </template>

          <template v-slot:item.pmDateExtended="{ item }">
            <div
              :class="`${
                item.highlightPmDate ? 'red--text font-weight-bold' : ''
              }`"
            >
              {{ item.pmDateExtended }}
            </div>
          </template>
          <template v-slot:item.insuranceSlipDate="{ item }">
            <div
              :class="`${
                item.highlightInsuranceSlipDate
                  ? 'red--text font-weight-bold'
                  : ''
              }`"
            >
              {{ item.insuranceSlipDate }}
            </div>
          </template>
          <template v-slot:item.transponderDate="{ item }">
            <div
              :class="`${
                item.highlightTransponderDate
                  ? 'red--text font-weight-bold'
                  : ''
              }`"
            >
              {{ item.transponderDate }}
            </div>
          </template>

          <template v-slot:item.cvorDate="{ item }">
            <div
              :class="`${
                item.highlightCvorDate ? 'red--text font-weight-bold' : ''
              }`"
            >
              {{ item.cvorDate }}
            </div>
          </template>

          <template v-slot:item.iftaDate="{ item }">
            <div
              :class="`${
                item.highlightIftaDate ? 'red--text font-weight-bold' : ''
              }`"
            >
              {{ item.iftaDate }}
            </div>
          </template>

          <template v-slot:item.kentuckyDate="{ item }">
            <div
              :class="`${
                item.highlightKentuckyDate ? 'red--text font-weight-bold' : ''
              }`"
            >
              {{ item.kentuckyDate }}
            </div>
          </template>

          <template v-slot:item.leaseExpireDate="{ item }">
            <div
              :class="`${
                item.highlightLeaseExpireDate
                  ? 'red--text font-weight-bold'
                  : ''
              }`"
            >
              {{ item.leaseExpireDate }}
            </div>
          </template>

          <template v-slot:item.mcs90Date="{ item }">
            <div
              :class="`${
                item.highlightMcs90Date ? 'red--text font-weight-bold' : ''
              }`"
            >
              {{ item.mcs90Date }}
            </div>
          </template>

          <template v-slot:item.pinkSlipDate="{ item }">
            <div
              :class="`${
                item.highlightPinkSlipDate ? 'red--text font-weight-bold' : ''
              }`"
            >
              {{ item.pinkSlipDate }}
            </div>
          </template>
          <template v-slot:item.newMaxicoPermitDate="{ item }">
            <div
              :class="`${
                item.highlightNewMaxicoPermitDate
                  ? 'red--text font-weight-bold'
                  : ''
              }`"
            >
              {{ item.newMaxicoPermitDate }}
            </div>
          </template>

          <template v-slot:item.newYorkPermitDate="{ item }">
            <div
              :class="`${
                item.highlightNewYorkPermitDate
                  ? 'red--text font-weight-bold'
                  : ''
              }`"
            >
              {{ item.newYorkPermitDate }}
            </div>
          </template>

          <template v-slot:item.originPermitDate="{ item }">
            <div
              :class="`${
                item.highlightOriginPermitDate
                  ? 'red--text font-weight-bold'
                  : ''
              }`"
            >
              {{ item.originPermitDate }}
            </div>
          </template>
          <template v-slot:item.ucrDate="{ item }">
            <div
              :class="`${
                item.highlightUcrDate ? 'red--text font-weight-bold' : ''
              }`"
            >
              {{ item.ucrDate }}
            </div>
          </template>
          <template v-slot:item.nmftaDate="{ item }">
            <div
              :class="`${
                item.highlightNmftaDate ? 'red--text font-weight-bold' : ''
              }`"
            >
              {{ item.nmftaDate }}
            </div>
          </template>
          <template v-slot:item.cabCardDate="{ item }">
            <div
              :class="`${
                item.highlightCabCardDate ? 'red--text font-weight-bold' : ''
              }`"
            >
              {{ item.cabCardDate }}
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
import EventBus from "@/eventBus";
import AddTruck from "@/components/AddTruck.vue";
import AddLoader from "@/components/AddLoader";
import format from "date-fns/format";
import CustomLog from "@/plugins/customlog";
const BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  data() {
    return {
      menu: false,
      hints: true,
      loading: false,
      message: "",
      resultMsg: "",
      items: [],
      userInfo: {},
      avatarSize: 60,
      tile: false,
      page: 1,
      action: "get",
      totalItems: 0,
      optionLinks: [
        { title: "Hide", action: "hide" },
        { title: "Show", action: "show" },
      ],

      searchText: "", // new data start
      search: "",
      headers: [
        {
          text: "Truck Id",
          align: "start",
          sortable: false,
          value: "refId",
          divider: true,
          width: 80,
        },
        { text: "VIN", value: "vin", divider: true, width: 150 },
        { text: "Fuel Type", value: "fuelType", divider: true, width: 80 },
        {
          text: "License Issue Province",
          value: "issueProvince",
          divider: true,
          width: 100,
        },
        {
          text: "License Plate Number",
          value: "plateNumber",
          divider: true,
          width: 100,
        },
        { text: "Year", value: "year", divider: true, width: 80 },
        { text: "Make", value: "make", divider: true, width: 100 },
        { text: "Model", value: "model", divider: true, width: 80 },
        {
          text: "Under Taking Company",
          value: "company.title",
          divider: true,
          width: 150,
        },
        {
          text: "Annual Safety",
          value: "annualSafetyDateExtended",
          divider: true,
          width: 120,
        },
        { text: "PM", value: "pmDateExtended", divider: true, width: 120 },
      ],
      bottom: false,
      fieldsList: [],
      enableFields: true,
    };
  },
  created() {
    this.userInfo = this.$store.state.loggedinInfo;
    this.loadSettings();
  },
  mounted() {
    EventBus.$on("truck-added", (error) => {
      CustomLog.log(error);
      this.page = 1;
      this.loadList(this.page, "get");
    });
    EventBus.$on("truck-update", (error) => {
      CustomLog.log(error);
      this.loadList(this.page, "get");
    });
  },
  watch: {
    bottom(bottom) {
      if (bottom) {
        CustomLog.log("Next Page Data");
      }
    },
  },
  methods: {
    rowClass(item) {
      var rowClass = "active-row";
      if (item.status.id == "2") {
        rowClass = "inactive-row";
      }
      return rowClass;
    },
    exportData() {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("adminId", this.userInfo.id);
      formData.append("action", "truck");
      axios
        .post(BASE_URL + "export.php", formData, {
          responseType: "arraybuffer",
        })
        .then(function (response) {
          vm.loading = false;
          const url = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `All-Trucks-${new Date().toLocaleDateString()}.csv`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch(function (error) {
          vm.loading = false;
          CustomLog.log(error);
        });
    },
    addTableScrollListener() {
      document
        .querySelector(".v-data-table__wrapper")
        .addEventListener("scroll", () => {
          this.bottom = this.bottomVisible();
        });
    },
    bottomVisible() {
      const scrollY = document.querySelector(
        ".v-data-table__wrapper"
      ).scrollTop;
      const tableHeight = document.querySelector(
        ".v-data-table__wrapper table"
      ).clientHeight;
      const visible = document.querySelector(
        ".v-data-table__wrapper "
      ).clientHeight;
      const pageHeight = document.querySelector(
        ".v-data-table__wrapper"
      ).scrollHeight;
      if (tableHeight >= visible) {
        const bottomOfPage = visible + scrollY >= pageHeight;
        return bottomOfPage || pageHeight < visible;
      } else {
        return false;
      }
    },
    formattedDate(date) {
      if (date == "0000-00-00") {
        return "Not assigned yet";
      } else {
        return format(date, "Do MMM YYYY");
      }
    },
    loadList(page, action) {
      this.loading = true;
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("adminId", this.userInfo.id);
      formData.append("action", action);
      const config = {
        headers: {
          accept: "application/json",
        },
        data: {},
      };
      axios
        .post(BASE_URL + "truck.php?page=" + page, formData, config)
        .then(function (response) {
          vm.loading = false;
          CustomLog.log(response);
          //Add Scroll Listener
          if (vm.items.length <= 0) {
            vm.addTableScrollListener();
          } //end
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.items = response.data.list;
            vm.totalItems = parseInt(response.data.totalItems);
            //vm.page = parseInt(response.data.page);
          }
        })
        .catch(function (error) {
          vm.loading = false;
          CustomLog.log(error);
        });
    },
    loadSettings() {
      const vm = this;
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("adminId", this.userInfo.id);
      formData.append("action", "get");
      const config = {
        headers: {
          accept: "application/json",
        },
        data: {},
      };
      axios
        .post(BASE_URL + "appSetting.php", formData, config)
        .then(function (response) {
          vm.loading = false;
          CustomLog.log(response);
          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.fieldsList = response.data.list;
            CustomLog.log(vm.fieldsList);
            vm.updateHeader();
          }
          vm.loadList(vm.page, vm.action);
        })
        .catch(function (error) {
          vm.loading = false;
          CustomLog.log(error);
        });
    },
    updateHeader() {
      this.headers = [
        {
          text: "Truck Id",
          align: "start",
          sortable: false,
          value: "refId",
          divider: true,
          width: 80,
        },
        { text: "VIN", value: "vin", divider: true, width: 150 },
        { text: "Fuel Type", value: "fuelType", divider: true, width: 80 },
        {
          text: "License Issue Province",
          value: "issueProvince",
          divider: true,
          width: 100,
        },
        {
          text: "License Plate Number",
          value: "plateNumber",
          divider: true,
          width: 100,
        },
        { text: "Year", value: "year", divider: true, width: 80 },
        { text: "Make", value: "make", divider: true, width: 100 },
        { text: "Model", value: "model", divider: true, width: 80 },
        {
          text: "Under Taking Company",
          value: "company.title",
          divider: true,
          width: 150,
        },
        {
          text: "Annual Safety",
          value: "annualSafetyDateExtended",
          divider: true,
          width: 120,
        },
        { text: "PM", value: "pmDateExtended", divider: true, width: 120 },
      ];
      this.fieldsList.forEach((field) => {
        switch (field.value) {
          case "transpoder":
            if (field.status) {
              this.headers.push({
                text: "Transponder",
                value: "transponderDate",
                divider: true,
                width: 120,
              });
            }
            break;
          case "cvor":
            if (field.status) {
              this.headers.push({
                text: "CVOR",
                value: "cvorDate",
                divider: true,
                width: 120,
              });
            }
            break;
          case "ifta":
            if (field.status) {
              this.headers.push({
                text: "IFTA",
                value: "iftaDate",
                divider: true,
                width: 120,
              });
            }
            break;
          case "kentucky":
            if (field.status) {
              this.headers.push({
                text: "Kentucky",
                value: "kentuckyDate",
                divider: true,
                width: 120,
              });
            }
            break;

          case "lease_expiry":
            if (field.status) {
              this.headers.push({
                text: "Lease Expire",
                value: "leaseExpireDate",
                divider: true,
                width: 120,
              });
            }
            break;

          case "mcs90":
            if (field.status) {
              this.headers.push({
                text: "MCS90",
                value: "mcs90Date",
                divider: true,
                width: 120,
              });
            }
            break;
          case "pink_slip":
            if (field.status) {
              this.headers.push({
                text: "Pink Slip",
                value: "pinkSlipDate",
                divider: true,
                width: 120,
              });
            }
            break;
          case "new_maxico_permit":
            if (field.status) {
              this.headers.push({
                text: "New Mexico Permit",
                value: "newMaxicoPermitDate",
                divider: true,
                width: 120,
              });
            }
            break;
          case "new_york_permit":
            if (field.status) {
              this.headers.push({
                text: "New York Permit",
                value: "newYorkPermitDate",
                divider: true,
                width: 120,
              });
            }
            break;
          case "origin_permit":
            if (field.status) {
              this.headers.push({
                text: "Origin Permit",
                value: "originPermitDate",
                divider: true,
                width: 120,
              });
            }
            break;
          case "ucr":
            if (field.status) {
              this.headers.push({
                text: "UCR",
                value: "ucrDate",
                divider: true,
                width: 120,
              });
            }
            break;
          case "nmfta":
            if (field.status) {
              this.headers.push({
                text: "NMFTA",
                value: "nmftaDate",
                divider: true,
                width: 120,
              });
            }
            break;
          case "cab_card":
            if (field.status) {
              this.headers.push({
                text: "Cab Card",
                value: "cabCardDate",
                divider: true,
                width: 120,
              });
            }
            break;
          case "mc_certificate":
            if (field.status) {
              this.headers.push({
                text: "MC Certificate",
                value: "mcCertificate",
                divider: true,
                width: 80,
              });
            }
            break;
          case "nmfta_scac":
            if (field.status) {
              this.headers.push({
                text: "NMFTA SCAC",
                value: "nmftaScac",
                divider: true,
                width: 80,
              });
            }
            break;

          default:
            break;
        }
      });
      this.headers.push({
        text: "Action",
        value: "action",
        sortable: false,
        divider: true,
        width: 100,
      });
    },
    saveFieldsSetting() {
      this.updateHeader();
      this.menu = false;
      const vm = this;
      let fieldsString = vm.getFieldsAsString(this.fieldsList);
      var formData = new FormData();
      formData.append("ran", Math.random() * 10);
      formData.append("adminId", this.userInfo.id);
      formData.append("action", "update");
      formData.append("fields", fieldsString);
      CustomLog.log(this.userInfo.fname);
      axios
        .post(BASE_URL + "appSetting.php", formData)
        .then(function (response) {
          vm.loading = false;
          if (response.data.error) {
            vm.message = response.data.message;
          }
        })
        .catch(function (error) {
          vm.loading = false;
          CustomLog.log(error);
        });
    },
    getFieldsAsString(fields) {
      let fieldsArray = [];
      //.join()
      fields.forEach((field) => {
        if (field.status) {
          fieldsArray.push(field.value);
        }
      });
      return fieldsArray.join();
    },
    getPageData(page) {
      CustomLog.log(page);
      this.loadList(page, this.action);
    },
    update(data) {
      CustomLog.log(data.id);
      EventBus.$emit("edit-truck", data);
    },
    searchQuery(page) {
      CustomLog.log(this.searchText.length);
      if (this.searchText.length > 2) {
        this.loading = true;
        const vm = this;
        var formData = new FormData();
        formData.append("keyword", vm.searchText);
        formData.append("adminId", vm.userInfo.id);
        formData.append("action", "search");
        axios
          .post(BASE_URL + "truck.php?page=" + page, formData)
          .then(function (response) {
            vm.loading = false;
            CustomLog.log(response);
            if (response.data.error) {
              vm.message = response.data.message;
              if (page == 1) {
                vm.resultMsg = "No result found";
                vm.items = [];
              }
            } else {
              vm.items = response.data.list;
            }
          })
          .catch(function (error) {
            vm.loading = false;
            CustomLog.log(error);
          });
      } else if (this.searchText.length == 0) {
        this.page = 1;
        this.loadList(this.page, this.action);
      }
    },
  },
  components: { AddTruck, AddLoader },
};
</script>
<style scoped>
tr.active-row {
  color: #222;
}
tr.inactive-row {
  color: #b1b1b1;
}
.max-width {
  max-width: 229px;
  padding-top: 0px !important;
  margin-top: 25px !important ;
}
.search-field {
  padding-top: 0px !important;
  margin-top: 0px !important ;
  max-width: 300px;
}
.check-box .v-label {
  text-transform: capitalize;
  margin-left: 6px;
}
</style>
